          <template>
  <div class="enquiries"  v-if="typeof dashboardelementindex === 'undefined'">
  <v-dialog v-model="importresultsdialog" max-width="800px">
        <v-card dark height="100%" width ="100%" class="center secondary">
          <v-img src="@/assets/RABaseBG.jpeg">
          <v-card-title>
            Import Result<v-spacer></v-spacer><v-icon @click="CloseImportresultsdialog()">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>Successful Imports ({{SuccessfulImportsArray.length}})</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content v-for="header in ImportHeaders" :key="header.itemObjKey">
                          {{header.toUpperCase()}}
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-for="res in SuccessfulImportsArray" :key="res.itemObjKey">
                        <v-list-item-content v-for="header in ImportHeaders" :key="header.itemObjKey">
                          {{res[header]}}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Partial Failures ({{PartialFailuresArray.length}})</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content v-for="header in ImportHeaders" :key="header.itemObjKey">
                          {{header.toUpperCase()}}
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-for="res in PartialFailuresArray" :key="res.itemObjKey">
                        <v-list-item-content v-for="header in ImportHeaders" :key="header.itemObjKey">
                          {{res[header]}}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer><v-btn href="/DataImports" target="_blank" color="green" dark>View Import Record</v-btn>
          
          </v-card-actions>
          </v-img>
        </v-card>
      </v-dialog>

      <v-dialog v-model="ImportingProgressDialog" fullscreen="">
        <v-card height="100%" width ="100%" class="center" color="rgb(240, 240, 240, 0.95)">
              <v-container bg fill-height grid-list-md text-xs-center>
              <v-layout row wrap align-center>
              <v-card-text class="justify-center">
                <h2 class="center">Importing Records</h2><br>
                Now Importing - 
                          <div class="red--text">
                          <p>{{Importcount}}</p>
                          </div>
                <v-layout class="justify-center">
                  <div class="loader"></div>
                  </v-layout>
                  <v-layout row class="justify-center mx-3 my-3">
                      <progress id="importprogressbar">
                      </progress>
                  </v-layout>
              </v-card-text>
              </v-layout>
              </v-container>
        </v-card>
      </v-dialog>
    
            <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome!Enquiry.</span>
      <v-btn color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>
     
           <v-snackbar v-model="importsnackbar" :timeout="4000" top color="success">
      <span>Awesome! You imported the file. You can view your import results <a href="/DataImports" target="_blank">here</a></span>
      <v-btn color="white" @click="importsnackbar = false">Close</v-btn>
    </v-snackbar>
    
     <v-parallax class="recordparallax2"
    height="150"
        src="@/assets/RASolidA.jpeg"
        v-if="!$route.params.id  && AppisDarkMode  && !PageView"
    >
    <v-row
          align="center"
          justify="center"
        >
    <v-col class="stdparallaxgradient text-center" cols="12">
    <span class="largeoverline font-weight-thin"><v-icon class="white--text" x-large></v-icon>Enquiries</span>
    </v-col>
  </v-row>
    </v-parallax>
    <v-parallax class="recordparallax2"
    height="150"
        src="@/assets/RASolidA-Light.jpeg"
        v-if="!$route.params.id  && !AppisDarkMode || PageView"
    >
    <v-row
          align="center"
          justify="center"
        >
    <v-col class="stdparallaxgradient text-center" cols="12">
    <span class="largeoverline font-weight-thin"><v-icon class="white--text" x-large></v-icon>Enquiries</span>
    </v-col>
  </v-row>
    </v-parallax>

    <v-layout class="justify-center" style="padding-top: 70px"  id="enquiriesskeleton">
      <v-flex lg10 md10> 
    <v-sheet
        color="secondary"
        class="mx-6 my-3"
        style="margin-top:150px;"
      >
      <div class="hidden-sm-and-down">
            <h1 class="Header white--text">Enquiries</h1>
        </div>
        <v-skeleton-loader
          class="mx-auto"
        
          type="table-thead"
        ></v-skeleton-loader>
        <v-skeleton-loader v-for="n in 5" :key="n.itemObjKey"
          class="mx-auto"
          
          type="table-row"
        ></v-skeleton-loader>
      </v-sheet>
  </v-flex>
 </v-layout>

        
    <v-layout class="justify-center"  :style="CollectionPadding">
      <v-flex    lg10 md10>
     <div id="enquiriesdata" style="display:none">
    
      
          
              <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-img
            width="500"
            src="@/assets/RABaseBG-Soft.png"
            ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
              <v-card-title class="mediumoverline white--text">
                {{ formTitle }}<v-spacer></v-spacer><v-icon  class="actionicon" x-large color="white">mdi-help</v-icon>
              </v-card-title>
              <v-card-subtitle class="overline white--text">
              <v-icon  class="actionicon" small :color="formSubTitleIconColor">{{formSubTitleIcon}}</v-icon> {{formSubTitle}}
            </v-card-subtitle></div>
                <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="12" md="12" lg="12">
                        <v-text-field v-model="editedItem.Name" color="red" :rules="[rules.required]" id="Name" label="* Name"></v-text-field>
                        
                        
                          
                        
                        
                        
                        
                          <v-combobox
                            class="FormFields"
                            v-model="editedItem.Status"
                            :items="StatusOptions"
                            hide-selected
                            item-text="Name"
                            label="Status Options"
                            small-chips
                            solo 
                      
                            flat
                          ></v-combobox>
                          
                          
                        
                        
                        
                        
                          <v-combobox
                            class="FormFields"
                            v-model="editedItem.Status_Reason"
                            :items="Status_ReasonOptions"
                            hide-selected
                            item-text="Name"
                            label="Status Reason Options"
                            small-chips
                            solo 
                      
                            flat
                          ></v-combobox>
                          
                          
                        
                        
                        
                        
                          
                          <v-combobox
                            class="FormFields"
                            v-model="editedItem.Owner"
                            :items="OwnerArray"
                            item-text="Full_Name"
                            hide-selected
                            label="Search Owner"
                            small-chips
                            solo 
                            flat
                            @change="AssignBU()"
                          ></v-combobox>
                          
                        
                      </v-col>
                    </v-row>       
                </v-card-text>
                <v-card-actions class="white documenttypeshadowinvert">                  
                <v-btn color="orange" dark class="overline" @click="close()">Cancel</v-btn>
                  <v-spacer></v-spacer>
                <v-btn color="green" dark  class="overline" @click="save()">Save</v-btn>
              </v-card-actions>
                 </v-img>
              </v-card>
            </v-dialog>
  
        <v-dialog v-model="importdialog" max-width="500px">
               <v-card>
                 <v-img
                    width="500"
                    src="@/assets/RABaseBG-Soft.png"
                    ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
                      <v-card-title class="mediumoverline white--text">
                        Import Enquiries<v-spacer></v-spacer><v-icon  class="actionicon" x-large color="white">mdi-help</v-icon>
                      </v-card-title>
                      <v-card-subtitle class="overline white--text">
                      <v-icon  class="actionicon" small color="green">mdi-database-import</v-icon> Import Enquiries here
                    </v-card-subtitle>
                    <v-card-subtitle class="overline white--text">
                     <v-icon  class="actionicon" @click="DownloadEnquiriesImportTemplate()" small color="white">mdi-download</v-icon> Download Enquiries Template
                    </v-card-subtitle></div>
                 <v-card-text>
                     <v-row>
                       <v-col cols="12" sm="12" md="12" lg="12">
                         <input v-if="!ImportFile"
                                   type="file"
                                   @change="onImportfileSelected($event)"
                                   ref="ImportFileinputter"
                                   id="fileUpload"
                                   >
                         <v-text-field class="FormFields" v-model="Import.Name" label="Import File Name"></v-text-field>
                         
                         
                       </v-col>
                     </v-row>
                   </v-card-text>
                   <v-card-actions class="white documenttypeshadowinvert">                  
                  <v-btn color="orange" dark class="overline" @click="closeImport()">Cancel</v-btn>
                    <v-spacer></v-spacer>
                  <v-btn color="green" dark  class="overline" @click="saveImport()">Save</v-btn>
                </v-card-actions>
                 </v-img>
               </v-card>
             </v-dialog>

    
          <v-dialog v-model="exportdialog" max-width="500">
      <v-card>
        <v-img
          width="500"
          src="@/assets/RABaseBG-Soft.png"
          ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
            <v-card-title class="mediumoverline white--text">
              Export Enquiries<v-spacer></v-spacer><v-icon  class="actionicon" x-large color="white">mdi-help</v-icon>
            </v-card-title>
            <v-card-subtitle class="overline white--text">
            <v-icon  class="actionicon" small color="green">mdi-database-export</v-icon> Export Enquiries here
          </v-card-subtitle>
          </div>
        <v-card-text class="FormFields">
          This will export all data in your curent view, in .CSV Format. Choose below whether to Export just for viewing, or to Export for importing of Re-Importing the sheet
          to perform Bulk updates to the Enquiries Records:
          <v-list class="transparent">
            <v-list-item>
              <v-btn @click="ExportData()" class="overline blue--text" text>Export for View</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn @click="ExportDataforReimport()" class="overline blue--text" text>Export for Reimport</v-btn>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions  class="white documenttypeshadowinvert">
          <v-btn color="orange" dark class="overline" @click="closeExport()">Cancel</v-btn>
          </v-card-actions>           
        </v-img>
      </v-card>
    </v-dialog>



        
         <v-expand-transition mode="fade" duration=2000 v-if="processmapview">
             <ProcessMapComponent @AssignProcessID="AssignProcessID"
             @ActivateProcessMapFilter="ActivateProcessMapFilter"
              :EntityProcesses="EntityProcesses"
              :ChartDatalength="EnquiriesFilteredandSearched.length" 
              @Viewtable="Viewtable"  :selectedLabelIdentifier="selectedLabelIdentifier" 
              :selectedLabel="selectedLabel" @ActivateActiveChart="ActivateActiveChart"
               @ChartDataSelect="ChartDataSelect" :headers="ComputedHeaders"
                :collection="'enquiries'" 
                :chipname="'Name'"
                :ProcessMapfilters="ProcessMapfilters"
                :Labels="ComputedStageNameOptions" :DataIcon="'mdi-help'" :Data="ProcessMapData"/>
              </v-expand-transition>
              
    <v-layout row class="justify-center">
      <v-card class="transparent" flat width="90%">
        <v-toolbar rounded src="@/assets/RABaseBG.jpeg" elevation="12" style="margin-bottom:-40px;" color="primary white--text">
          <v-toolbar-title  class="mediumoverline"> <v-icon  class="mx-1" x-large color="white">mdi-help</v-icon>Enquiries</v-toolbar-title>
          <v-divider
            class="mx-4 white"
            inset
            vertical
          ></v-divider>
          <v-icon  class="actionicon mx-1" color="white">mdi-view-sequential</v-icon>
          <v-icon  class="actionicon mx-1" color="white">mdi-view-grid</v-icon>
         
          <v-spacer></v-spacer>
          <v-text-field dark
            v-model="search"
            
            label="Search"
            single-line
            hide-details
            class="mediumoverline"
          ></v-text-field><v-icon @click="ResetSearch()" v-if="search === ''" dark>mdi-file-search</v-icon><v-icon @click="ResetSearch()" v-if="search !== ''" dark>mdi-close</v-icon>
          <v-spacer></v-spacer>
          <v-menu>
            <template v-slot:activator="{ on }">
            <v-btn outlined fab small class="elevation-6" dark>
              <v-icon  v-on="on" v-if="CanCreate || userIsAdmin || HasCharts || HasProcesses" dark>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG.jpeg') + ')' }" dark>
              <v-list-item class="listoutline"  @click="dialog = !dialog"  v-if="CanCreate || userIsAdmin">
                  <v-list-item-title class="subtleoverline">
                    New
                  </v-list-item-title>
                <v-list-item-action>
                    <v-icon class="actionicon" color="pop">mdi-plus-thick</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item  class="listoutline" @click="importdialog = !importdialog" v-if="UserRecord.CanImport || userIsAdmin">
                  <v-list-item-title class="subtleoverline">
                    Import
                  </v-list-item-title>
                <v-list-item-action>
                    <v-icon class="actionicon" color="grey">mdi-database-import</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item  class="listoutline" @click="ActivateExportDialog(EnquiriesArray,headers)"  v-if="UserRecord && UserRecord.CanExport || userIsAdmin">
                  <v-list-item-title class="subtleoverline">
                    Export
                  </v-list-item-title>
                <v-list-item-action>
                    <v-icon class="actionicon" color="grey">mdi-database-export</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item  class="listoutline" @click="ActivateProcessMapView()">
                  <v-list-item-title class="subtleoverline">
                    Process Map
                  </v-list-item-title>
                <v-list-item-action>
                    <v-icon class="actionicon" color="blue">mdi-play-network</v-icon>
                  </v-list-item-action>
                </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-card>
    </v-layout>
        <v-data-table
      :page.sync="page"
      @page-count="pageCount = $event"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :dark="AppisDarkMode"
      :headers="ComputedHeaders"
      :items="EnquiriesFilteredandSearched"
      class="elevation-1"
      id="enquiriesdatatable"
    >
      <template v-slot:top>
        <div style="padding-top:40px;"/>
      </template>
        <template v-slot:[`item.Owner`]="{ item }">
      <v-avatar size="35">
        <v-img :src="item.Owner.Profile_Photo" v-if="item.Owner && item.Owner.Profile_Photo"/>
        <v-img src="@/assets/BlankProfilePic.png" v-if="!item.Owner || !item.Owner.Profile_Photo"/>
        </v-avatar>
        <span v-if="item.Owner">{{' '+item.Owner.Full_Name}}</span>
    </template>
    <template v-slot:[`item.Name`]="{ item }">
        <v-btn outlined fab small @click="ViewItem(item)" class="elevation-6 accent--text"><v-icon small v-if="item && CanGet">mdi-factory</v-icon></v-btn> {{item.Name}}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
      <v-btn outlined fab x-small @click="editItem(item)" class="elevation-6 orange--text"
       v-if="item && item && CanEdit">
          <v-icon small>
            mdi-pencil
            </v-icon>
        </v-btn>
        <v-btn outlined fab x-small @click="deleteItem(item)" class="elevation-6 red--text"
        v-if="item && item && CanDelete">
          <v-icon small>
            mdi-trash-can-outline
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>
      <template v-slot:footer>
      <v-card outlined>
        <v-pagination
        :total-visible="10"
          v-model="page"
          circle
          :length="pageCount"
        ></v-pagination>
        <v-text-field
          :value="itemsPerPage"
          label="Items per page"
          type="number"
          min="-1"
          max="15"
          @input="itemsPerPage = parseInt($event, 10)"
        ></v-text-field>
        </v-card>
      </template>

    </v-data-table>

    
    
    </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import ProcessMapComponent from '@/components/Processes/ProcessMapComponent'


export default {
  props: ['UsersArray','dashboardelementindex','IntranetView','RelatedObj','PageFilter','PageView','RelatedParentObj','RelatedGroupFilter','DashboardView','DashboardArray','CompSize','AllowsHistoric','ReferenceView','DocTableCall','RenderComp','AppisDarkMode'],
  components: {
    ProcessMapComponent,
  },
  data() {
    return {
  UserRecord: '',
  page: 1,
  pageCount: 0,
  itemsPerPage: 10,
  ParentFilterField: '',
  processmapview: false,  
  HasProcesses: true,    
  ProcessID: '',
  ProcessMapSelectedFilter: '',
  EntityProcesses: [
  {
    "ProcessStages": [
      {
        "IsHeader": true,
        "Type": "Stage",
        "Automations": [],
        "Name": "Incoming",
        "Number": 1000001,
        "Steps": []
      },
      {
        "Steps": [],
        "Type": "Stage",
        "Automations": [],
        "Number": 1000002,
        "Name": "In Progress",
        "IsHeader": true
      },
      {
        "IsHeader": true,
        "Type": "Stage",
        "Name": "Completed",
        "Number": 1000003,
        "Steps": [],
        "Automations": []
      }
    ],
    "DisplayName": "Basic Enquiry",
    "ProcessStageInteger": 1000003,
    "id": "1000001",
    "Name": "Basic Enquiry",
    "IsHeader": true,
    "Number": 1000001,
    "Automations": [],
    "Type": "Process"
  }
],
  ExportDataArray: [],
  ExportHeaders: [],
  exportdialog: false,
  
  
  StatusOptions: [{
  "Name": "Active",
  "ID": 1000001
},{
  "ID": 1000002,
  "Name": "InActive"
}],
  Statusfilter: '',Status_ReasonOptions: [{
  "Name": "Active",
  "ID": 1000001
},{
  "Name": "InActive",
  "ID": 1000002
}],
  Status_Reasonfilter: '',
  Created_ByArray: [],
  Created_Byfilter: {Full_Name: ''},
  OwnerArray: [],
  Ownerfilter: {Full_Name: ''},
  dialog: false,
  initialize: '',
  search: '',
  UsersArray:[],
  BusinessUnitsArray: [],
  headers: [
    { text: 'Name', value: 'Name',class: "overline"},
    
    { text: 'Status', value: 'Status.Name',class: "overline"},
    { text: 'Status Reason', value: 'Status_Reason.Name',class: "overline"},
    { text: 'Ownerid', value: 'Owner.id',class: "overline",Identifier: true},
    { text: 'Owner', value: 'Owner',class: "overline"},
    { text: 'Actions', value: 'actions', sortable: false ,class: "overline"},
  ],
  snackbar: false,
  Reimport: false,
  importdialog: false,
  importsnackbar: false,
  ImportHeaders: [],
  ImportingProgressDialog: false,
  Importcount: '',
  importresultsdialog: false,
  NewImportsArray: [],
  PartialFailuresArray: [],
  SuccessfulImportsArray: [],
  CSVResult: [],
  Import: {Name: '',AllImports: [],PartialFailures: [], Dataset: 'Enquiries', CreatedOn: '', CreatedBy: {id: '', Name: ''}},
  ImportFile: '',
  EditedEnquirysnackbar: false,
  EnquiryEditdialog: false,
  newEnquirydialog: false,
  EnquiriesArray: [],
  rules: {
      min8Chars: value => value.length >= 8 || "Min. 8 characters",
      required: value => !!value || "Required.",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      url: value => {
        const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); 
        return urlpattern.test(value) || "Invalid Link.";
      }
    },
  editedIndex: -1,
  defaultItem: {
    id: '',
      Name: '',
      Surname: '',
      Enquiry: '',
      Created_On: '',
      Created_By: '',
      Owner: '',
      Email: '',
  Email:  '',
      Telephone_Number: '',
  Telephone_Number:  '',
  },
  editedItem: {
    id: '',
      Name: '',
      Surname: '',
      Enquiry: '',
      Status: {ID: 1000001,Name: 'Active' },
      Status_Reason: {ID: 1000001,Name: 'Active' },
      Created_On: '',
      Created_By: '',
      Owner: '',
      Email: '',
      Email:  '',
      Telephone_Number: '',
      Telephone_Number:  '',
  },
  UndefinedMandatoryFields: [],
  CollectionRef: db.collection('enquiries'),
    
    }
  },

  mounted() {
    if(this.CompSize){
    this.$vuetify.breakpoint.width = this.CompSize
    }
  },
  
  created(){
    if(this.PageView){
      this.itemsPerPage = 5
    }
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if(this.CompSize){
      this.$vuetify.breakpoint.width = this.CompSize
      console.log(this.$vuetify.breakpoint)
    }
    
setTimeout(() => {
  document.getElementById('enquiriesskeleton').style.display = 'none'
  document.getElementById('enquiriesdata').style.display = 'block'      
  },1500)


window.onload = setTimeout(() => {this.CheckRouting()},1500)



  },
  computed: {
        ComputedHeaders(){
    return this.headers.filter(header => {
      return !header.Identifier
    })
  },
    EnquiriesQuery(){
    return this.$store.state.EnquiriesQuery 
    },
  CanGet(){
    return this.$store.state.EnquiriesCanView
  },
  CanList(){
    return this.$store.state.EnquiriesCanList
  },
  CanEdit(){
    return this.$store.state.EnquiriesCanEdit
  },
  CanDelete(){
    return this.$store.state.EnquiriesCanDelete
  },
  CanCreate(){
    return this.$store.state.EnquiriesCanCreate
  },
    
    CollectionPadding(){
    if(!this.$route.params.id && !this.PageView){
      return 'padding-top: 70px'
    }
    else if(this.PageView){
    return 'padding: 20px'
    }
    else{
      return ''
    }
  },
    EnquiriesStore() {
      return this.$store.getters.getEnquiriesArray
    },
    
  ProcessMapfilters(){
    let Created_ByArrayTemp = this.Created_ByArray.map(created_by => {
      created_by.identifier = 'Full_Name'
      created_by.childidentifier = 'Created_By' 
      created_by.childidentifiervalue = 'Created_By.Full_Name'
      created_by.ProcessFilterName = 'Created_By'
      created_by.FieldType = 'Lookup'
      console.log(created_by)
      return created_by
    })
    let OwnerArrayTemp = this.OwnerArray.map(owner => {
      owner.identifier = 'Full_Name'
      owner.childidentifier = 'Owner' 
      owner.childidentifiervalue = 'Owner.Full_Name'
      owner.ProcessFilterName = 'Owner'
      owner.FieldType = 'Lookup'
      console.log(owner)
      return owner
    })
    return Created_ByArrayTemp.concat(OwnerArrayTemp)
    
  },
  ProcessMapData(){
    return this.EnquiriesArray.filter(enquiries => {
      if(this.ProcessID){
      return enquiries.ActiveProcess && enquiries.ActiveProcess.Number  === this.ProcessID
      }
      else{
        return enquiries
      }
    }).filter(enquiries => {
      if(this.ProcessMapSelectedFilter){
        if(this.ProcessMapSelectedFilter.FieldType === 'Lookup'){
          if(enquiries[this.ProcessMapSelectedFilter.childidentifier]){
          return enquiries[this.ProcessMapSelectedFilter.childidentifier].id === this.ProcessMapSelectedFilter.id
          }
        }
        if(this.ProcessMapSelectedFilter.FieldType === 'Option Set'){
          if(enquiries[this.ProcessMapSelectedFilter.childidentifier]){
          return enquiries[this.ProcessMapSelectedFilter.childidentifier].Number === this.ProcessMapSelectedFilter.Number
          }
        }
      }
      else{
        return enquiries
      }
    })
  },
  ComputedStageNameOptions(){
  if(this.ProcessID){
    if(this.ProcessID === 1000001){
      return [
  {
    "IsHeader": true,
    "Type": "Stage",
    "Automations": [],
    "Name": "Incoming",
    "Number": 1000001,
    "Steps": []
  },
  {
    "Steps": [],
    "Type": "Stage",
    "Automations": [],
    "Number": 1000002,
    "Name": "In Progress",
    "IsHeader": true
  },
  {
    "IsHeader": true,
    "Type": "Stage",
    "Name": "Completed",
    "Number": 1000003,
    "Steps": [],
    "Automations": []
  }
]
    }
  }
},
  userIsAdmin () {
    return this.$store.state.IsAdmin
  },
  userLoggedIn () {
    return this.$store.getters.user
  },
  userBUID () {
    return this.$store.getters.userBUID
  },
  userBUChildren () {
    return this.$store.getters.userBUChildren
  },
  userBUParents () {
    return this.$store.getters.userBUParents
  },
  UsersStore(){
    return this.UsersArray
  },
  UsersStore(){
      return this.$store.state.UsersArray
    },
  UsersStore(){
      return this.$store.state.UsersArray
    },
  UsersStore(){
      return this.$store.state.UsersArray
    },
  FinalEnquiriesCreated_ByArray(){
    return this.EnquiriesArray.map(Enquiry => {
      let lookupobject = this.Created_ByArray.find(Created_By => Created_By.id === Enquiry.Created_Byid)
      Enquiry.Created_By = lookupobject
      return Enquiry
    })
    },
  FinalEnquiriesOwnerArray(){
    return this.EnquiriesArray.map(Enquiry => {
      let lookupobject = this.OwnerArray.find(Owner => Owner.id === Enquiry.Ownerid)
      Enquiry.Owner = lookupobject
      return Enquiry
    })
    },
    FinalDashboardArray(){
    return this.EnquiriesArray
        .map(Enquiry => {
        let lookupobject = this.Created_ByArray.find(Created_By => Created_By.id === Enquiry.Created_Byid)
        Enquiry.Created_By = lookupobject
        return Enquiry
      })
        .map(Enquiry => {
        let lookupobject = this.OwnerArray.find(Owner => Owner.id === Enquiry.Ownerid)
        Enquiry.Owner = lookupobject
        return Enquiry
      })
    },
    formTitle () {
      return this.editedIndex === -1 ? 'New Enquiry' : 'Edit Enquiry'
    },
    formSubTitle(){
      return this.editedIndex === -1 ? 'Fill the form below and add a new Enquiry' : 'Edit the Enquiry '+this.editedItem.Name
    },
    formSubTitleIcon(){
      return this.editedIndex === -1 ? 'mdi-plus-thick' : 'mdi-pencil'
    },
    formSubTitleIconColor(){
      return this.editedIndex === -1 ? 'green' : 'orange'
    },
  EnquiriesSearched() {
    return this.EnquiriesArray.filter(Enquiry => {
      if(Enquiry.Name){return Enquiry.Name.toLowerCase().includes(this.search.toLowerCase())}
    }).filter(Enquiry => {
      if(this.$route.params.id && this.RelatedObj || this.$route.params.id && this.RelatedParentObj){
        if(this.ParentFilterField && Enquiry[this.ParentFilterField]){
          return Enquiry[this.ParentFilterField].DocumentID === this.$route.params.id
        }          
      }
      else{
        return Enquiry
      }
    })
    },
    EnquiriesFilteredandSearched() {
    return this.EnquiriesSearched.filter(Enquiry => {return Enquiry}).map(enquiry => {
      if(this.OwnerArray){
        let lookupobject = this.OwnerArray.find(Owner => Owner.id === enquiry.Ownerid)
          enquiry.Owner = lookupobject
          return enquiry
      }
      else{
        return enquiry
      }
          
        }).map(enquiry => {
        return enquiry
        }).map(enquiry => {
        if(this.PageView && this.PageFilter){
          if(this.PageFilter.FieldType === 'Option Set'){
            if(enquiry[this.PageFilter.FieldName]){
              this.PageFilter.Filter.map(filter => {
                if(filter.ID === enquiry[this.PageFilter.FieldName].ID){
                  enquiry.Render = true
                }
              })
            }
          }
        }
        else{
          enquiry.Render = true
        }
        return enquiry
      })
      .filter(enquiry => {
        return enquiry.Render === true
      })
    },
    
    HeaderNames() {
      return this.headers.map(header => {
        return header.value.split('.')[0]
      }).filter(header => {
        return header !== 'id'
      })
    },
    HeaderNamesSplit() {
      return this.HeaderNames.map(header => {
       let correctheader = header
        return correctheader
      })
    },
  
    },
  watch: {
      userLoggedIn (value) {
        //if (value !== null && value !== undefined) {
         // this.$router.push('/')
        //}
      },
      FinalDashboardArray (value) {
        if(value && value.length > 0){
          let vm = this
          if(typeof this.dashboardelementindex !== 'undefined'){
               
                let lookuparrays = [
                  {
                    Field: 'Created_By',
                    Array: this.Created_ByArray
                  },
                  {
                    Field: 'Owner',
                    Array: this.OwnerArray
                  },
                ]
                vm.$emit('updatearray',this.dashboardelementindex,this.FinalDashboardArray,lookuparrays)
              }
        }
      },
    },
  methods: {
    
      ActivateProcessMapFilter(selectedfilter){
      console.log('use it',selectedfilter)
      if(selectedfilter.FieldType === 'Lookup'){
        this.ProcessMapSelectedFilter = selectedfilter
      }
    },
    AssignProcessID(processID){
      this.ProcessID = processID
    },
    ActivateProcessMapView(){
      if(!this.processmapview){
      this.processmapview = true
      }
      else{
        this.processmapview = false
      }
    },
    ActivateExportDialog(array,headers){
      this.ExportDataArray = array.map(item => {
        delete item.Children
        delete item.headers
        return item
      })
      this.ExportHeaders = Array.from(headers)
      this.exportdialog = true
    },
    closeExport(){
      this.exportdialog = false
      this.ExportData = []
      this.ExportHeaders = []
    },
    ExportData(){
     this.exportToCsv('download.csv',this.ExportDataArray)
    },
    ExportDataforReimport(){
    this.exportToCsv('download.csv',this.ExportDataArray,true)
  },
    exportToCsv(filename, exportdata,forreimport) {
  const headers = this.ExportHeaders.map(header => {
    return header.value.split('.')[0]
  })
  const headerrows = [headers]
  if(forreimport === true){
    headers.push('id')
    let idobj = { text: 'id', value: 'id'}
    this.ExportHeaders.push(idobj)
    headers.push('id')
    this.ExportHeaders.push(idobj)
  }
  console.log(this.ExportDataArray)
  console.log(this.ExportHeaders)
  const datarows = exportdata.map(row => {
    row.csvdata =  this.ExportHeaders.map(header => {
      
      let headervalue = ''
      let firstprop = header.value.split('.')[0]
      let secondprop = header.value.split('.')[1]
      
      if(secondprop){
        
        if(row[firstprop]){
          if(row[firstprop][secondprop]){   
            let thirdprop = row[firstprop][secondprop]
                         
          headervalue = row[firstprop][secondprop]
          }
        }
      }
      else{
        if(row[firstprop]){
          
        headervalue = row[firstprop]
        }
      }   
      if(header.text === 'Owner'){
        if(row.Owner){
          headervalue = row.Owner.Full_Name
        }
        
      }         
      return headervalue
    })
    return row
    }).map(row => {
      return row.csvdata
    })
   const rows = headerrows.concat(datarows)
  

  let csvContent = "data:text/csv;charset=utf-8," 
      + rows.map(e => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "my_data.csv");
      document.body.appendChild(link); // Required for FF

      link.click();
  },
      
  DateFormatter(date){
    if(date){
      let removeT = date.split('T').join(' ')
      let removeFWDslash = date.split('/').join('-')
    let yearstring = removeFWDslash.split('-')[0]
      let monthstring = removeFWDslash.split('-')[1]
      let daystring = removeFWDslash.split('-')[2]
      let yearnumber = Number(yearstring)
      let monthnumber = Number(monthstring)
      let daynumber = Number(daystring)
      return new Date(yearnumber, monthnumber-1, daynumber)
      }
    else{
      return null
    }
  },
  FromExcelDateFormatter(date){
  if(date){
  let yearstring = date.split('/')[0]
    let monthstring = date.split('/')[1]
    let daystring = date.split('/')[2]
    let yearnumber = Number(yearstring)
    let monthnumber = Number(monthstring)
    let daynumber = Number(daystring)
    return new Date(yearnumber, monthnumber-1, daynumber)
    }
  else{
    return null
  }
},
  CheckRouting(){
    
      if(typeof this.$route.params.id !== 'undefined'){
        //this.defaultItem[this.RelatedObj.identifier] = this.RelatedObj
            //this.editedItem = Object.assign({}, this.defaultItem)
            let queryfield = this.RelatedObj.identifier+'.DocumentID'
            
            if(typeof this.RelatedParentObj !== 'undefined'){
              //this.defaultItem[this.RelatedParentObj.identifier] = this.RelatedParentObj
              let queryfield = this.RelatedParentObj.identifier+'.DocumentID'
              this.ParentFilterField = this.RelatedParentObj.identifier
              this.CollectionRef = this.EnquiriesQuery.where(queryfield,'==',this.RelatedParentObj.id)
              this.GetRequestingUser(true)
            }
            
            else{
              let queryfield = this.RelatedObj.identifier+'.DocumentID'
              this.ParentFilterField = this.RelatedObj.identifier
              this.CollectionRef = this.EnquiriesQuery.where(queryfield,'==',this.RelatedObj.id)
              this.GetRequestingUser(true)
            }
        //this.GetUsers()
      }
      else if(this.DashboardView){
        this.EnquiriesArray = this.DashboardArray
      }
      else{
        
        this.GetRequestingUser()
        this.GetUsers()
      }
  },
  
  
    GetUsers(){
    db.collection('users').onSnapshot(res => {

    const changes = res.docChanges();
    changes.forEach(change => {
      if (change.type === 'added') {
        this.UsersArray.push({
          ...change.doc.data(),
          id: change.doc.id
        })
      }


    })
  })
  },

  GetBusinessUnits(){
    db.collection('businessunits').onSnapshot(res => {

    const changes = res.docChanges();
    changes.forEach(change => {
      if (change.type === 'added') {
        this.BusinessUnitsArray.push({
          ...change.doc.data(),
          id: change.doc.id
        })
      }


    })
  })
  },

  closeImport(){
    this.importdialog = false
    this.ImportFile = null
    this.Import.Name = ''
  },

  saveImport() {
    this.ImportingProgressDialog = true
    setTimeout(() => {
    const totalprogresselement = document.getElementById('importprogressbar')
    totalprogresselement.setAttribute('max','100')
    totalprogresselement.setAttribute('value',1)
  }, 200);
    this.Importcount = 'Preparing Import'
    let importLocalURL = URL.createObjectURL(this.ImportFile)
    var reader = new FileReader();
    let test = reader.readAsText(this.ImportFile);
    let vm = this
    reader.addEventListener('load', function(e) {
          var text = e.target.result;
          vm.CSVConvertToJSON(text)
    });
  },


  CheckDelimiter(lines){
    let headertest = lines[0].split(";");
    if(headertest.length ===1){
      this.ConversionCSVtoJJSON(lines,',')
    }
    else{
      this.ConversionCSVtoJJSON(lines, ';')
    }
    console.log('headertest')
    console.log(headertest)
  },
  ConversionCSVtoJJSON(lines,delimiter){
    let dataonly = Object.assign([],lines)
    dataonly.splice(0,1)
    console.log(dataonly)
    this.CSVResult = []
    this.ImportHeaders=lines[0].split(delimiter);
    console.log('this.ImportHeaders')
    console.log(this.ImportHeaders)
    let finalindex = this.ImportHeaders.length-1
  let finalheader = this.ImportHeaders[finalindex]
  if(finalheader.includes('id')){
    this.Reimport = true
    confirm('We picked up this is a Reimport. Shall you continue?') && this.ProceedReimport(lines,delimiter)
  }
  else{
    for(var i=1;i-1+2<lines.length;i++){

    var obj = {};
    var currentline=lines[i].split(delimiter);
    
    for(var j=0;j<this.ImportHeaders.length;j++){
      obj[this.ImportHeaders[j]] = currentline[j];
    }
    let resultcheck = this.CSVResult.find(res => res.Name == obj.Name)
    this.CSVResult.push(obj);
    console.log("this.CSVResult length is "+this.CSVResult.length)


  }
  this.Importcount = 'Preparing '+this.CSVResult.length+' records...'
    this.CSVResult.map((res,i) => {
      
      this.UpdateFiresStore(res,i)
      
    })
  }
  },
  ProceedReimport(lines,delimiter){
  for(var i=1;i-1+2<lines.length;i++){
      var obj = {};
      var currentline=lines[i].split(delimiter);
      
      for(var j=0;j<this.ImportHeaders.length;j++){
        obj[this.ImportHeaders[j]] = currentline[j];
      }
      let resultcheck = this.CSVResult.find(res => res.Name == obj.Name)
      this.CSVResult.push(obj);
      console.log("this.CSVResult length is "+this.CSVResult.length)
    }
    this.Importcount = 'Preparing '+this.CSVResult.length+' records...'
      this.CSVResult.map((res,i) => {
          this.UpdateFiresStoreReimport(res,i)
      })
},
  CSVConvertToJSON(text){
   
    this.NewImportsArray = []
    this.PartialFailuresArray = []
    
    let lines = text.split("\n")
    this.CheckDelimiter(lines)
    
  },
  UpdateFiresStoreReimport(SingleResult, Index){
    console.log("Index")
    console.log(Index)
    let ResultLength = this.CSVResult.length
    console.log("ResultLength")
    console.log(ResultLength)
    console.log("Index-1+2")
    console.log(Index-1+2)
    let OriginalImportObject = Object.assign({},SingleResult)                
                  
                      let NameQuery = []
                        let NameStringArray = SingleResult.Name.trim()
                        var lowertext = "";                
                        var p;
                          let buildup = ''
                        console.log(NameStringArray.length,NameStringArray)
                          for (p = 0; p < NameStringArray.length; p++) {
                              buildup = buildup+ NameStringArray[p]
                                lowertext += buildup.toLowerCase() + ",";
                                }                   
                          NameQuery = lowertext.split(',')  
                          NameQuery.length = NameQuery.length-1
                  
    let ImportEnquiry = {
      Name: SingleResult.Name.trim(),
      NameQuery: NameQuery,
      Modified_By: {id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name},
      Modified_Byid: this.UserRecord.id,
      Modified_On: new Date(), 
      ImportedRecord: true
      }
      
        ImportEnquiry.id = SingleResult.id
      
      
      let FailureObject = Object.assign({},ImportEnquiry)
      FailureObject.Failures = []
      if(SingleResult.Owner !== ''){
        let GetObj = this.OwnerArray.find(comp => comp.Full_Name.trim() == SingleResult.Owner)
        if(typeof GetObj === 'undefined'){
          FailureObject.Owner = 'Failed - No Matching Record Found'
          ImportEnquiry.hasFailures = true
          let FailureInstance = {Type: 'No Matching Lookup Record', Name: 'Owner'}
          FailureObject.Failures.push(FailureInstance)
        }
        else{
        let OwnerObject = {id: GetObj.id,Full_Name: GetObj.Full_Name}
        ImportEnquiry.Owner = OwnerObject
        ImportEnquiry.Ownerid = OwnerObject.id
        }

      }
      if(SingleResult.Status !== ''){
        let Options = [
  {
    "Name": "Active",
    "ID": 1000001
  },
  {
    "ID": 1000002,
    "Name": "InActive"
  }
]
        let GetObj = Options.find(option => option.Name == SingleResult.Status)
        if(typeof GetObj === 'undefined'){
          FailureObject.Status = 'Failed - No Matching Option Found'
          ImportEnquiry.hasFailures = true
          let FailureInstance = {Type: 'No Matching Option', Name: 'Status'}
          FailureObject.Failures.push(FailureInstance)
        }
        else{
        let StatusObject = {ID: GetObj.ID,Name: GetObj.Name}
        ImportEnquiry.Status = StatusObject
        }

      }
      if(SingleResult.Status_Reason !== ''){
        let Options = [
  {
    "Name": "Active",
    "ID": 1000001
  },
  {
    "Name": "InActive",
    "ID": 1000002
  }
]
        let GetObj = Options.find(option => option.Name == SingleResult.Status_Reason)
        if(typeof GetObj === 'undefined'){
          FailureObject.Status_Reason = 'Failed - No Matching Option Found'
          ImportEnquiry.hasFailures = true
          let FailureInstance = {Type: 'No Matching Option', Name: 'Status_Reason'}
          FailureObject.Failures.push(FailureInstance)
        }
        else{
        let Status_ReasonObject = {ID: GetObj.ID,Name: GetObj.Name}
        ImportEnquiry.Status_Reason = Status_ReasonObject
        }

      }
      
      if(ImportEnquiry.hasFailures){
      this.PartialFailuresArray.push(FailureObject)

      }
      else{
        this.SuccessfulImportsArray.push(OriginalImportObject)
      }

      this.NewImportsArray.push(OriginalImportObject)
      // db.collection('enquiries').add(ImportEnquiry).then({
      // })
      db.collection('enquiries').doc(ImportEnquiry.id).update({
      Name: ImportEnquiry.Name,
      NameQuery: ImportEnquiry.NameQuery,
      Status: ImportEnquiry.Status,
      Status_Reason: ImportEnquiry.Status_Reason,
      Owner: ImportEnquiry.Owner,
      Modified_By: ImportEnquiry.Modified_By,
      Modified_Byid: ImportEnquiry.Modified_Byid,
      Modified_On: ImportEnquiry.Modified_On, 
      ImportedRecord: true
        
      })
      
     
        let AllImportObj = this.NewImportsArray.find(imp => imp.Name == ImportEnquiry.Name)
        if(typeof AllImportObj !== 'undefined'){
        let AllImportObjIndex = this.NewImportsArray.indexOf(AllImportObj)
        this.NewImportsArray[AllImportObjIndex].id = ImportEnquiry.id
        console.log(this.NewImportsArray[AllImportObjIndex])
        console.log(this.NewImportsArray)
        }
        let PartialImportObj = this.PartialFailuresArray.find(imp => imp.Name == ImportEnquiry.Name)

        if(typeof PartialImportObj !== 'undefined'){
        let PartialImportObjIndex = this.PartialFailuresArray.indexOf(PartialImportObj)
        this.PartialFailuresArray[PartialImportObjIndex].id = ImportEnquiry.id
        console.log(this.PartialFailuresArray[PartialImportObjIndex])
        console.log(this.PartialFailuresArray)
        }
        let SuccessImportObj = this.SuccessfulImportsArray.find(imp => imp.Name == ImportEnquiry.Name)
        if(typeof SuccessImportObj !== 'undefined'){
        let SuccessmportObjIndex = this.SuccessfulImportsArray.indexOf(SuccessImportObj)
        this.SuccessfulImportsArray[SuccessmportObjIndex].id = ImportEnquiry.id
        console.log(this.SuccessfulImportsArray[SuccessmportObjIndex])
        console.log(this.SuccessfulImportsArray)
        }
        let indexstring = Index.toString()
      let totallength = ResultLength.toString()
      let runningPercentage = Number(((Index/ResultLength)*100).toFixed(2))
      this.Importcount = 'Importing '+runningPercentage+'% complete ('+indexstring+' of '+totallength+')'
      const totalprogresselement = document.getElementById('importprogressbar')
      totalprogresselement.setAttribute('value',runningPercentage)
        if (Index-1+2 === ResultLength){
      console.log("NewImportsArray")
      console.log(this.NewImportsArray)
      console.log("PartialFailuresArray")
      console.log(this.PartialFailuresArray)

      let Created_On = new Date()
      let User = this.UserRecord
      let RefinedHeaders = this.ImportHeaders.map(header => {
        let obj = {text: header, value: header}
        return obj
      })
      console.log("this.NewImportsArray at final length")
      console.log(this.NewImportsArray)
      let DataImport = {name: this.Import.Name, dataset: 'Enquiries', datasetrecordpath: 'Enquiry', createdon: Created_On, createdby: {id: User.id, Full_Name: User.Name+' '+User.Surname}, importheaders: RefinedHeaders, allimports: this.NewImportsArray, partialfailures: this.PartialFailuresArray, successfulimports: this.SuccessfulImportsArray, dataimportstatus: 'Imported'}
      db.collection('dataimports').add(DataImport).then(doc => {
      var storageRef = firebase.storage().ref('DataImport/'+Created_On+'/'+this.ImportFile.name);
      var uploadTask = storageRef.put(this.ImportFile);
      uploadTask
      .then(snapshot => snapshot.ref.getDownloadURL())
        .then((url) => {
          db.collection('dataimports').doc(doc.id).update({
                  csvfile: url
          })
          this.ImportFile = ''
              }),
              this.importresultsdialog = true
              this.importdialog = false
              this.Import.Name = ''
              this.ImportingProgressDialog = false
      })

      }
      


  },
  UpdateFiresStore(SingleResult, Index){
    console.log("Index")
    console.log(Index)
    let ResultLength = this.CSVResult.length
    console.log("ResultLength")
    console.log(ResultLength)
    console.log("Index-1+2")
    console.log(Index-1+2)
    let OriginalImportObject = Object.assign({},SingleResult)                
                  
                      let NameQuery = []
                        let NameStringArray = SingleResult.Name.trim()
                        var lowertext = "";                
                        var p;
                          let buildup = ''
                        console.log(NameStringArray.length,NameStringArray)
                          for (p = 0; p < NameStringArray.length; p++) {
                              buildup = buildup+ NameStringArray[p]
                                lowertext += buildup.toLowerCase() + ",";
                                }                   
                          NameQuery = lowertext.split(',')  
                          NameQuery.length = NameQuery.length-1
                  
    let ImportEnquiry = {
      Name: SingleResult.Name.trim(),
      NameQuery: NameQuery,
      Created_By: {id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name},
      Created_Byid: this.UserRecord.id,
      Created_On: new Date(),        
      Status: {ID: 1000001, Name: 'Active'},
      Status_Reason: {ID: 1000001, Name: 'Active'},
      ImportedRecord: true
      }
      
   
      
      let FailureObject = Object.assign({},ImportEnquiry)
      FailureObject.Failures = []
      if(SingleResult.Owner !== ''){
        let GetObj = this.OwnerArray.find(comp => comp.Full_Name.trim() == SingleResult.Owner)
        if(typeof GetObj === 'undefined'){
          FailureObject.Owner = 'Failed - No Matching Record Found'
          ImportEnquiry.hasFailures = true
          let FailureInstance = {Type: 'No Matching Lookup Record', Name: 'Owner'}
          FailureObject.Failures.push(FailureInstance)
        }
        else{
        let OwnerObject = {id: GetObj.id,Full_Name: GetObj.Full_Name}
        ImportEnquiry.Owner = OwnerObject
        ImportEnquiry.Ownerid = OwnerObject.id
        }

      }
      if(SingleResult.Status !== ''){
        let Options = [
  {
    "Name": "Active",
    "ID": 1000001
  },
  {
    "ID": 1000002,
    "Name": "InActive"
  }
]
        let GetObj = Options.find(option => option.Name == SingleResult.Status)
        if(typeof GetObj === 'undefined'){
          FailureObject.Status = 'Failed - No Matching Option Found'
          ImportEnquiry.hasFailures = true
          let FailureInstance = {Type: 'No Matching Option', Name: 'Status'}
          FailureObject.Failures.push(FailureInstance)
        }
        else{
        let StatusObject = {ID: GetObj.ID,Name: GetObj.Name}
        ImportEnquiry.Status = StatusObject
        }

      }
      if(SingleResult.Status_Reason !== ''){
        let Options = [
  {
    "Name": "Active",
    "ID": 1000001
  },
  {
    "Name": "InActive",
    "ID": 1000002
  }
]
        let GetObj = Options.find(option => option.Name == SingleResult.Status_Reason)
        if(typeof GetObj === 'undefined'){
          FailureObject.Status_Reason = 'Failed - No Matching Option Found'
          ImportEnquiry.hasFailures = true
          let FailureInstance = {Type: 'No Matching Option', Name: 'Status_Reason'}
          FailureObject.Failures.push(FailureInstance)
        }
        else{
        let Status_ReasonObject = {ID: GetObj.ID,Name: GetObj.Name}
        ImportEnquiry.Status_Reason = Status_ReasonObject
        }

      }
      

      if(ImportEnquiry.hasFailures){
      this.PartialFailuresArray.push(FailureObject)

      }
      else{
        this.SuccessfulImportsArray.push(OriginalImportObject)
      }

      this.NewImportsArray.push(OriginalImportObject)
      // db.collection('enquiries').add(ImportEnquiry).then({
      // })
      db.collection('enquiries').add(ImportEnquiry).then(doc => {
        let AllImportObj = this.NewImportsArray.find(imp => imp.Name == ImportEnquiry.Name)
        if(typeof AllImportObj !== 'undefined'){
        let AllImportObjIndex = this.NewImportsArray.indexOf(AllImportObj)
        this.NewImportsArray[AllImportObjIndex].id = doc.id
        console.log(this.NewImportsArray[AllImportObjIndex])
        console.log(this.NewImportsArray)
        }
        let PartialImportObj = this.PartialFailuresArray.find(imp => imp.Name == ImportEnquiry.Name)

        if(typeof PartialImportObj !== 'undefined'){
        let PartialImportObjIndex = this.PartialFailuresArray.indexOf(PartialImportObj)
        this.PartialFailuresArray[PartialImportObjIndex].id = doc.id
        console.log(this.PartialFailuresArray[PartialImportObjIndex])
        console.log(this.PartialFailuresArray)
        }
        let SuccessImportObj = this.SuccessfulImportsArray.find(imp => imp.Name == ImportEnquiry.Name)
        if(typeof SuccessImportObj !== 'undefined'){
        let SuccessmportObjIndex = this.SuccessfulImportsArray.indexOf(SuccessImportObj)
        this.SuccessfulImportsArray[SuccessmportObjIndex].id = doc.id
        console.log(this.SuccessfulImportsArray[SuccessmportObjIndex])
        console.log(this.SuccessfulImportsArray)
        }
        let indexstring = Index.toString()
      let totallength = ResultLength.toString()
      let runningPercentage = Number(((Index/ResultLength)*100).toFixed(2))
      this.Importcount = 'Importing '+runningPercentage+'% complete ('+indexstring+' of '+totallength+')'
      const totalprogresselement = document.getElementById('importprogressbar')
      totalprogresselement.setAttribute('value',runningPercentage)
        if (Index-1+2 === ResultLength){
      console.log("NewImportsArray")
      console.log(this.NewImportsArray)
      console.log("PartialFailuresArray")
      console.log(this.PartialFailuresArray)

      let Created_On = new Date()
      let User = this.UserRecord
      let RefinedHeaders = this.ImportHeaders.map(header => {
        let obj = {text: header, value: header}
        return obj
      })
      console.log("this.NewImportsArray at final length")
      console.log(this.NewImportsArray)
      let DataImport = {name: this.Import.Name, dataset: 'Enquiries', datasetrecordpath: 'Enquiry', createdon: Created_On, createdby: {id: User.id, Full_Name: User.Name+' '+User.Surname}, importheaders: RefinedHeaders, allimports: this.NewImportsArray, partialfailures: this.PartialFailuresArray, successfulimports: this.SuccessfulImportsArray, dataimportstatus: 'Imported'}
      db.collection('dataimports').add(DataImport).then(doc => {
      var storageRef = firebase.storage().ref('DataImport/'+Created_On+'/'+this.ImportFile.name);
      var uploadTask = storageRef.put(this.ImportFile);
      uploadTask
      .then(snapshot => snapshot.ref.getDownloadURL())
        .then((url) => {
          db.collection('dataimports').doc(doc.id).update({
                  csvfile: url
          })
          this.ImportFile = ''
              }),
              this.importresultsdialog = true
              this.importdialog = false
              this.Import.Name = ''
              this.ImportingProgressDialog = false
      })

      }
      })


  },
  CloseImportresultsdialog(){
    this.importresultsdialog = false
  },
  onImportfileSelected(event){
    this.ImportFile = event.target.files[0]
  },

  DownloadEnquiriesImportTemplate(){
    let copyText = this.HeaderNamesSplit

    var csvfilename = 'Enquiries.csv'
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(copyText))
    element.setAttribute('download', csvfilename)
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    // }
  },
  ResetFilters() {},
  ResetSearch() {
    this.search = ''
  },
    
  BURolesCheck(RoleObject){
        let vm = this
        let CreatePermissionLevel = 0
        let GetPermissionLevel = 0
        let ListPermissionLevel = 0
        let UpdatePermissionLevel = 0
        let DeletePermissionLevel = 0
        let Roleslength = vm.UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        vm.UserRoles.map((role,i) => {
          let alldeltest = RoleObject.AlldeleteRoles.find(obj => obj == role)
          let unitdowndeltest = RoleObject.UnitDowndeleteRoles.find(obj => obj == role)
          let userunitdeltest = RoleObject.UserUnitdeleteRoles.find(obj => obj == role)
          let ownerdeltest = RoleObject.OwnerdeleteRoles.find(obj => obj == role)
          let alledittest = RoleObject.AllupdateRoles.find(obj => obj == role)
          let unitdownedittest = RoleObject.UnitDownupdateRoles.find(obj => obj == role)
          let userunitedittest = RoleObject.UserUnitupdateRoles.find(obj => obj == role)
          let owneredittest = RoleObject.OwnerupdateRoles.find(obj => obj == role)
          let allviewtest = RoleObject.AllgetRoles.find(obj => obj == role)
          let unitdownviewtest = RoleObject.UnitDowngetRoles.find(obj => obj == role)
          let userunitviewtest = RoleObject.UserUnitgetRoles.find(obj => obj == role)
          let ownerviewtest = RoleObject.OwnergetRoles.find(obj => obj == role)
          let alltest = RoleObject.AlllistRoles.find(obj => obj == role)
          let peertest = RoleObject.UnitDownlistRoles.find(obj => obj == role)
          let parenttest = RoleObject.UserUnitlistRoles.find(obj => obj == role)
          let childtest = RoleObject.OwnerlistRoles.find(obj => obj == role)
          let allcreatetest = RoleObject.AllcreateRoles.find(obj => obj == role)
          let peercreatetest = RoleObject.UnitDowncreateRoles.find(obj => obj == role)
          let parentcreatetest = RoleObject.UserUnitcreateRoles.find(obj => obj == role)
          let childcreatetest = RoleObject.OwnercreateRoles.find(obj => obj == role)
          if(typeof alldeltest !== 'undefined' || vm.IsAdmin){
            DeletePermissionLevel = 4
          }
          else if(typeof unitdowndeltest !== 'undefined'){
            if(DeletePermissionLevel<3){
              DeletePermissionLevel = 3
            }
          }
          else if(typeof userunitdeltest !== 'undefined'){
            if(DeletePermissionLevel<2){
              DeletePermissionLevel = 2
            }
          }
          else if(typeof ownerdeltest !== 'undefined'){
            if(DeletePermissionLevel<1){
              DeletePermissionLevel = 1
            }
          }
          if(typeof alledittest !== 'undefined' || vm.IsAdmin){
            UpdatePermissionLevel = 4
          }
          else if(typeof unitdownedittest !== 'undefined'){
            if(UpdatePermissionLevel<3){
              UpdatePermissionLevel = 3
            }
          }
          else if(typeof userunitedittest !== 'undefined'){
            if(UpdatePermissionLevel<2){
              UpdatePermissionLevel = 2
            }
          }
          else if(typeof owneredittest !== 'undefined'){
            if(UpdatePermissionLevel<1){
              UpdatePermissionLevel = 1
            }
          }
          if(typeof allviewtest !== 'undefined' || vm.IsAdmin){
            GetPermissionLevel = 4
          }
          else if(typeof unitdownviewtest !== 'undefined'){
             if(GetPermissionLevel<3){
              GetPermissionLevel = 3
            }
          }
          else if(typeof userunitviewtest !== 'undefined'){
            if(GetPermissionLevel<2){
              GetPermissionLevel = 2
            }
          }
          else if(typeof ownerviewtest !== 'undefined'){
            if(GetPermissionLevel<1){
              GetPermissionLevel = 1
            }
          }
          if(typeof alltest !== 'undefined' || vm.IsAdmin){
            ListPermissionLevel = 4
          }
          else if(typeof peertest !== 'undefined'){
            if(ListPermissionLevel<3){
              ListPermissionLevel = 3
            }
          }
          else if(typeof parenttest !== 'undefined'){
            if(ListPermissionLevel<2){
              ListPermissionLevel = 2
            }
          }
          else if(typeof childtest !== 'undefined'){
            if(ListPermissionLevel<1){
              ListPermissionLevel = 1
            }
          }
          if(typeof allcreatetest !== 'undefined' || vm.IsAdmin){
            CreatePermissionLevel = 4
          }
          else if(typeof peercreatetest !== 'undefined'){
            if(CreatePermissionLevel<3){
              CreatePermissionLevel = 3
            }
          }
          else if(typeof parentcreatetest !== 'undefined'){
            if(CreatePermissionLevel<2){
              CreatePermissionLevel = 2
            }
          }
          else if(typeof childcreatetest !== 'undefined'){
            if(CreatePermissionLevel<1){
              CreatePermissionLevel = 1
            }
          }
            if(i-1+2 === Roleslength){
              let PermissionLevel = {
                CreatePermissionLevel: CreatePermissionLevel,
                GetPermissionLevel: GetPermissionLevel,
                ListPermissionLevel: ListPermissionLevel,
                UpdatePermissionLevel: UpdatePermissionLevel,
                DeletePermissionLevel: DeletePermissionLevel

              }
              resolve(PermissionLevel)
            }
          })
        })
      },
      BUListRolesCheck(RoleObject){
        let vm = this
        let ListPermissionLevel = 0
        let Roleslength = vm.UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        vm.UserRoles.map((role,i) => {
          let alltest = RoleObject.AlllistRoles.find(obj => obj == role)
          let peertest = RoleObject.UnitDownlistRoles.find(obj => obj == role)
          let parenttest = RoleObject.UserUnitlistRoles.find(obj => obj == role)
          let childtest = RoleObject.OwnerlistRoles.find(obj => obj == role)
           
          if(typeof alltest !== 'undefined' || vm.IsAdmin){
            ListPermissionLevel = 4
          }
          else if(typeof peertest !== 'undefined'){
            if(ListPermissionLevel<3){
              ListPermissionLevel = 3
            }
          }
          else if(typeof parenttest !== 'undefined'){
            if(ListPermissionLevel<2){
              ListPermissionLevel = 2
            }
          }
          else if(typeof childtest !== 'undefined'){
            if(ListPermissionLevel<1){
              ListPermissionLevel = 1
            }          
          }
            if(i-1+2 === Roleslength){
              let PermissionLevel = {
                ListPermissionLevel: ListPermissionLevel,
              }
              resolve(PermissionLevel)
            }
          })
        })
      },
      CustomRolesCheck(Roles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = vm.UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        vm.UserRoles.map((role,i) => {
          console.log(i)
              let Rolestest = Roles.find(obj => obj == role)
                if(Rolestest){
                  PermissionLevel = 1
                }      
                if(i-1+2 === Roleslength){
                  resolve(PermissionLevel)
                }
              })

        })
      },
      
      GetEnquiries() {
      let vm = this
      return new Promise(function(resolve, reject) {
        vm.CollectionRef.onSnapshot(res => {
          let listlength = res.docs.length
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                vm.EnquiriesArray.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
              if(vm.EnquiriesArray.length === listlength){
                  resolve('done calling EnquiriesArray')
              }
            })
         })
        })
      },
        GetRequestingUser(filteredcall){
      let vm = this
      if(this.userLoggedIn){
        this.UserRecord = this.userLoggedIn
        if(filteredcall === true){
          this.EnquiriesCall(this.CollectionRef)
          }   
        else if(vm.EnquiriesStore.length>0){
          
          
          vm.EnquiriesArray = vm.EnquiriesStore
          
              

                    if(vm.UsersStore.length > 0){
                      //alert('got from store')
                      vm.Created_ByArray = vm.UsersStore
                    }
                    else{
                    //alert('NOPE, MUST CALL from store')
                    let payload = {                
                      query: vm.$store.state.UsersQuery,
                      arraymutation: 'setUsersArray',
                      getter: 'getUsersArray',
                      arrayname: 'UsersArray'
                    }
                    vm.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                          console.log(vm.UsersStore)
                          vm.Created_ByArray = vm.UsersStore
                          })
                    }
                    if(vm.UsersStore.length > 0){
                      //alert('got from store')
                      vm.OwnerArray = vm.UsersStore
                    }
                    else{
                    //alert('NOPE, MUST CALL from store')
                    let payload = {                
                      query: vm.$store.state.UsersQuery,
                      arraymutation: 'setUsersArray',
                      getter: 'getUsersArray',
                      arrayname: 'UsersArray'
                    }
                    vm.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                          console.log(vm.UsersStore)
                          vm.OwnerArray = vm.UsersStore
                          })
                    }
    
                
          
        }
        else if (this.EnquiriesQuery){
          let payload = {                
                      query: vm.$store.state.EnquiriesQuery,
                      arraymutation: 'setEnquiriesArray',
                      getter: 'getEnquiriesArray',
                      arrayname: 'EnquiriesArray'
                    }
                    vm.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                          console.log(vm.EnquiriesStore)
                          vm.EnquiriesArray = vm.EnquiriesStore
                          })
          
            

                    if(vm.UsersStore.length > 0){
                      //alert('got from store')
                      vm.Created_ByArray = vm.UsersStore
                    }
                    else{
                    //alert('NOPE, MUST CALL from store')
                    let payload = {                
                      query: vm.$store.state.UsersQuery,
                      arraymutation: 'setUsersArray',
                      getter: 'getUsersArray',
                      arrayname: 'UsersArray'
                    }
                    vm.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                          console.log(vm.UsersStore)
                          vm.Created_ByArray = vm.UsersStore
                          })
                    }
                    if(vm.UsersStore.length > 0){
                      //alert('got from store')
                      vm.OwnerArray = vm.UsersStore
                    }
                    else{
                    //alert('NOPE, MUST CALL from store')
                    let payload = {                
                      query: vm.$store.state.UsersQuery,
                      arraymutation: 'setUsersArray',
                      getter: 'getUsersArray',
                      arrayname: 'UsersArray'
                    }
                    vm.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                          console.log(vm.UsersStore)
                          vm.OwnerArray = vm.UsersStore
                          })
                    }
    
              
          }
          else {
        vm.RoutetoLoginPage()
          }
       
      }
      else {
        vm.RoutetoLoginPage()
          }
          
      },
      RoutetoLoginPage(){
        this.$router.push('/login')
      },
      RoutetoErrorPage(){
        this.$router.push('/PermissionError')
      },
    
  EnquiriesCall(query){
    let vm = this
    query.onSnapshot(res => {
      let listlength = res.docs.length
      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.EnquiriesArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }
        if(this.EnquiriesArray.length === listlength && this.DocTableCall){
          vm.$emit('ReferencedDocsArrayPush',vm.EnquiriesArray,'EnquiriesArray',vm.headers)
        }          
      })
    })
  },
  
  
      GetUsersOwner(query) {
      query.onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.OwnerArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
      //console.log(this.FinalEnquiriesOwnerArray)
    })
      },
      
    

    ViewItem (item) {
      if(this.DashboardView || this.IntranetView){
        let routeData = this.$router.resolve({name: 'enquiry',params: {id: item.id}})
        window.open(routeData.href, '_blank')
      }
      
      else{
        this.$router.push('/Enquiry/'+item.id)
      }
      
    },

    deleteItem (item) {
      const index = this.EnquiriesArray.indexOf(item)
      confirm('Are you sure you want to delete this item?') && db.collection('enquiries').doc(item.id).delete() && this.EnquiriesArray.splice(index, 1)
    },

    editItem (item) {
      this.editedIndex = this.EnquiriesArray.indexOf(item)
      this.editedItem = Object.assign({}, item)
      
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.UndefinedMandatoryFields.map(prop => {
      
          let element = document.getElementById(prop)
            if(element){  
              var x = element.hasAttribute("class")
              if(x){        
                element.removeAttribute("class")
                }
              }
            
          })
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },  
    
    saveStageID(doc){
      let StageID = this.StageNameOptions.indexOf(this.editedItem.stagename)+1
      db.collection('enquiries').doc(doc.id).update({
        stageid: StageID,
        Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
        Modified_Byid: this.UserRecord.id,
        Modified_On: new Date()
      })
    },

    save(file) {
      this.UndefinedMandatoryFields = []
    const EditedEnquiry = {
  Name: this.editedItem.Name,
  Status: this.editedItem.Status,
  Status_Reason: this.editedItem.Status_Reason,
  Owner: this.editedItem.Owner.Full_Name,
    Ownerid: this.editedItem.Owner.id,
      }
      if (this.editedIndex > -1) {
       
    for(var prop in EditedEnquiry) {
        if (EditedEnquiry.hasOwnProperty(prop)) {
          if(typeof EditedEnquiry[prop] === 'undefined'){
            this.UndefinedMandatoryFields.push(prop)
          }
        else {
          let element = document.getElementById(prop)
            if(element){  
              var x = element.hasAttribute("class")
              if(x){        
                element.removeAttribute("class")
                }
              }
          }
          }
    
        else{
          console.log('something is amook')
        }
      }
      console.log(this.UndefinedMandatoryFields)
      if(this.UndefinedMandatoryFields.length === 0){
    db.collection('enquiries').doc(this.editedItem.id).update({
      Name: EditedEnquiry.Name,
      
      Status: EditedEnquiry.Status,
      
      Status_Reason: EditedEnquiry.Status_Reason,
      
      
      Owner: {Full_Name: this.editedItem.Owner.Full_Name,id: this.editedItem.Owner.id},
      Ownerid: this.editedItem.Owner.id,
      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
      Modified_Byid: this.UserRecord.id,
      Modified_On: new Date()
            });
            Object.assign(this.EnquiriesArray[this.editedIndex], this.editedItem)
            this.close()
            this.saveStageID(this.editedItem)
            this.EditedEnquirysnackbar = true
      }
      //here we loop for the optional fields and add them as we go
          
      }
      
       else {
        const NewEnquiry = {
    Name: EditedEnquiry.Name,
      
    Status: EditedEnquiry.Status,
      
    Status_Reason: EditedEnquiry.Status_Reason,
      
    
      Owner: {Full_Name: this.editedItem.Owner.Full_Name,id: this.editedItem.Owner.id},
      Ownerid: this.editedItem.Owner.id,        
      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
      Modified_Byid: this.UserRecord.id,
      Modified_On: new Date()
        }
        
        for(var prop in NewEnquiry) {
        if (NewEnquiry.hasOwnProperty(prop)) {
          if(typeof NewEnquiry[prop] === 'undefined' || NewEnquiry[prop] === ''){
            this.UndefinedMandatoryFields.push(prop)
            console.log(prop)
            console.log(NewEnquiry[prop])
          }
        else {
          let element = document.getElementById(prop)
            if(element){  
              var x = element.hasAttribute("class")
              if(x){        
                element.removeAttribute("class")
                }
              }
          }
          }
    
        else{
          console.log('something is amook')
        }
      }
      
        
          console.log(this.UndefinedMandatoryFields)
      if(this.UndefinedMandatoryFields.length === 0){
        NewEnquiry.Created_By = {Name: this.UserRecord.Name,Surname: this.UserRecord.Surname,Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id}
        NewEnquiry.Created_Byid = this.UserRecord.id,          
        NewEnquiry.Status = {ID: 1000001, Name: 'Active'},
        NewEnquiry.Status_Reason = {ID: 1000001, Name: 'Active'},
        NewEnquiry.Created_On = new Date(),
        NewEnquiry.Modified_By = {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
        NewEnquiry.Modified_Byid = this.UserRecord.id,
        NewEnquiry.Modified_On = new Date()
        NewEnquiry.Name = this.editedItem.Name
             
                        let NameQuery = []
                        let NameStringArray = this.editedItem.Name.split('')
                        var lowertext = "";                
                        var p;
                          let buildup = ''
                        console.log(NameStringArray.length,NameStringArray)
                          for (p = 0; p < NameStringArray.length; p++) {
                              buildup = buildup+ NameStringArray[p]
                                lowertext += buildup.toLowerCase() + ",";
                                }                   
                          NameQuery = lowertext.split(',')  
                          NameQuery.length = NameQuery.length-1
                       // db.collection('enquiries').doc(doc.id).update({
                          NewEnquiry.NameQuery = NameQuery,

                         // })
                      
        db.collection('enquiries').add(NewEnquiry).then(doc => {
                                
                    
                
            
                  
                  this.saveStageID(doc)                    
                  this.close()
        })
        
      }
        else{
      alert('you need to capture the values in '+this.UndefinedMandatoryFields)
      this.UndefinedMandatoryFields.map(field => {
        console.log('getting element by id '+field)
        let element = document.getElementById(field)
        
        element.setAttribute("class", "incompletedmandatory")
        console.log('here is element')
        console.log(element)
      })
    }
        
      }
      
    },
  
  }
}

</script>

 <style>
  
    
</style>
<style scoped>
.v-data-table{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color:#757575;
}
</style>

            
        